import xhr_headers from './xhr_headers.js'

// let modal;

const userSession = {
  modal: null,
  // listen to click on .auth-req
  // perform if authorized
  // showForm if unauthorized, then perform
  init(bootstrap){
    document.querySelectorAll('a.auth-req').forEach((link) => {
      let method = link.getAttribute('data-method') || 'get'
      link.removeAttribute('data-method')
      link.addEventListener('click', event => {
        event.stopImmediatePropagation();
        event.preventDefault();
        fetch(link.getAttribute('href'), { 
          method: method, 
          headers: xhr_headers,
        })
        .then(userSession.handleErrors)
        .then(response => response.text()).then(html => {
          link.click()
          // userSession.showForm(html, bootstrap).then(_ => {
          // })
        })
        .catch(error => error.text()).then(html => {
          userSession.showForm(html, bootstrap).then(_ => {
            link.click()
          })
        })
      })
    })
  },
  showForm(html, bootstrap){
    userSession.modal = document.getElementById('loginModal')
    return new Promise((resolve, reject) => {
      userSession.modal.innerHTML = html
      let loginModal = new bootstrap.Modal(userSession.modal)
      userSession.modal.addEventListener('shown.bs.modal', function () {
        document.getElementById('user_email').focus()
      })
      loginModal.show()
      userSession.bindSessionForm(resolve, reject)
      userSession.bindRegistrationForm(resolve, reject)
    })
  },
  bindSessionForm(resolve, reject){
    userSession.sessionFormPromise().then(_ => {
      resolve()
    }).catch(_ => {
      // reject()
    })
  },
  sessionFormPromise(){
    return new Promise((resolve, reject) => {
      let form = document.getElementById('new_user_session')
      form.addEventListener('submit', event => {
        event.preventDefault();
        let data = new URLSearchParams(new FormData(form));
        fetch(form.getAttribute('action'), { 
          headers: xhr_headers,
          method: 'post', 
          body: data
        })
        .then(userSession.handleErrors)
        .then(response => {
          resolve()
        }).catch(error => {
          if (error.status === 403) {
            userSession.showFlash('Te hemos enviado un email con las instrucciones para el inicio de sesión.')
          } else if (error.status === 422) {
            error.text().then(html => {
              userSession.showFlash(html, 'danger')
            })
          } else {
            error.text().then(html => {
              let newForm = document.createElement('div');
              newForm.innerHTML = html;
              form.parentNode.replaceChild(newForm, form);
              userSession.bindSessionForm()
            })
          }
          reject()
        })        
      })
    })
  },
  bindRegistrationForm(resolve, reject){
    userSession.registrationFormPromise().then(_ => {
      userSession.showFlash('Registro realizado con éxito<br />Te hemos enviado un email para confirmar tu cuenta. Por favor, revisa tu bandeja de entrada.')
      // resolve()
    }).catch(_ => {      
      // reject()
    })
  },
  registrationFormPromise(){
    return new Promise((resolve, reject) => {
      let container = document.getElementById("registration_form")
      let form = container.querySelector('form')
      container.addEventListener('show.bs.collapse', event => {
        form.querySelector('#user_name').scrollIntoView({ behavior: 'smooth', block: 'end'})
      })
      container.addEventListener('shown.bs.collapse', event => {
        form.querySelector('#user_name').focus()
      })
      form.addEventListener('submit', event => {
        event.preventDefault();
        let data = new URLSearchParams(new FormData(form));
        fetch(form.getAttribute('action'), { 
          headers: xhr_headers,
          method: 'post', 
          body: data
        })
        .then(userSession.handleErrors)
        .then(response => {
          resolve()
        }).catch(error => {
          error.text().then(html => {
            let newForm = document.createElement('div');
            newForm.innerHTML = html;
            form.parentNode.replaceChild(newForm, form);
            userSession.bindRegistrationForm()
          })
        })
      })
    })
  },
  handleErrors(response) {
    if (!response.ok) {
      throw response
    }
    return response;
  },
  showFlash(message, alertType='success'){
    let [title, content] = message.split('<br />')
    if (content === undefined) {content = ''}
    userSession.modal.querySelector(".modal-body").innerHTML = `
      <div class='alert alert-${alertType} fade show shadow rounded-0 mt-2 mb-4 py-2 px-3'>
        <div class="alert-header mt-1">
          <i class="alert-icon"></i>
          <div class="alert-title">${title}</div>
        </div>
        <div class="alert-content ps-2">
          ${content}
        </div>
      </div>
    `;
  }
}

export default userSession
