const i18n = {
  defaultLocale: 'es',
  translations: {
    es: {
      search_in_area: 'Buscar en esta zona',
      no_results: 'No hay resultados',
      locate_in_map: 'Muéstrame dónde estoy',
      current_position: 'Posición actual'
    },
    en: {
      search_in_area: 'Search in this area',
      no_results: 'No results',
      locate_in_map: 'Show me where I am',
      current_position: 'Current position'
    },
    fr: {
      search_in_area: 'Rechercher dans ce domaine',
      no_results: "Il n'y a pas de résultats",
      locate_in_map: "Montre-moi où je suis",
      current_position: 'Position actuelle'
    },
    it: {
      search_in_area: "Cerca in quest'area",
      no_results: "Nessun risultato",
      locate_in_map: "Mostrami dove sono",
      current_position: "Posizione attuale"
    },
    de: {
      search_in_area: "Suche in diesem Bereich",
      no_results: "Keine Ergebnisse",
      locate_in_map: "Zeig mir, wo ich bin",
      current_position: "Aktuelle Position"
    },
    pt: {
      search_in_area: "Pesquisar esta área",
      no_results: "Não há resultados",
      locate_in_map: "Mostre-me onde estou",
      current_position: "Posição atual"
    },
    zh: {
      search_in_area: "搜索该区域",
      no_results: "没有结果",
      locate_in_map: "告诉我我在哪里",
      current_position: "当前位置"
    }
  },
  translate(text){
    return i18n.translations[window.i18nLocale][text]
  },
  init(){
    if (document.getElementById('app')) {
       window.i18nLocale = document.getElementById('app').getAttribute('data-locale')  
    } else {
      window.i18nLocale = this.defaultLocale
    }
  }

}

export default i18n
