import xhr_headers from './xhr_headers.js'

const userRoutes = {
  modal: null,
  bsModal: null,
  init(bootstrap){
    userRoutes.modal = document.getElementById('userRoutesModal')
    if (userRoutes.modal) {
      document.addEventListener('favorites.reload', event => {
        userRoutes.addClickListener(event.detail.container, bootstrap);
      })
      let container = document.querySelector('main .fav-container')
      if (container){
        document.dispatchEvent(new CustomEvent('favorites.reload', {'detail': {'container': container}}))  
      }
    }
  },
  addClickListener(container, bootstrap){
    container.querySelectorAll('.toggle-user-route').forEach((link) => {
      link.removeAttribute('data-method')
      link.addEventListener('click', event => {
        event.stopImmediatePropagation();
        event.preventDefault();

        fetch(link.getAttribute('href'), { 
          headers: xhr_headers,
          method: 'post'
        })
        .then(response => response.text()).then(html => {
          userRoutes.modal.innerHTML = html
          userRoutes.bsModal = new bootstrap.Modal(userRoutes.modal)
          userRoutes.bsModal.show()
          userRoutes.bindForm(link)
        })
        .catch(error => error.text()).then(html => {
          console.log(`An error ocurred while toggling route shops`)
        })
      })
    })
  },
  bindForm(link){
    let form = userRoutes.modal.querySelector('form')
    form.addEventListener('submit', event => {
      event.preventDefault();
      let data = new URLSearchParams(new FormData(form));
      fetch(form.getAttribute('action'), { 
        headers: xhr_headers,
        method: 'post', 
        body: data
      })
      .then(response => {
        form.querySelector('input[type=submit]').removeAttribute('disabled')
        userRoutes.bsModal.hide();
        // link.classList.toggle('text-success')
      }).catch(error => {

      });
    })
  }
}

export default userRoutes
