const utilities = {
  elemIsVisible(elem){
    if (elem){
      var bounding = elem.getBoundingClientRect();
      if (typeof(elem.checkVisibility) == 'function')
        return elem.checkVisibility()
      else if (elem.checkVisibility != undefined) {
        return elem.checkVisibility
      } else {
        return (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      }
    }  
  }
}

export default utilities
