let MobileApp = {
  init(){
    let params = new URLSearchParams(window.location.search);
    if (params.has('app') && params.get('app') === '1'){
      window.location.replace(window.location.href.replace(window.location.protocol, 'cdir:'))
    }
  }
}

export default MobileApp;
