// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails          from "@rails/ujs"
import Turbolinks     from "turbolinks"
import bootstrap      from "bootstrap/dist/js/bootstrap.bundle.js"
import i18n           from '../plugins/i18n';
import Cookies        from "../plugins/cookies.js";
import MainNav        from "../plugins/main_nav.js";
import MobileApp      from "../plugins/mobile_app.js";
import Favorites      from "../plugins/favorites.js";
import UserRoutes     from "../plugins/user_routes.js";
import Share          from "../plugins/share.js";
import "animate.css";
import "../controllers"; // stimulus

Rails.start()
Turbolinks.start()

document.addEventListener('turbolinks:load', function(){
  let app = document.getElementById('app')

  i18n.init();
  Share.init(bootstrap);
  
  if (!app.getAttribute('data-embed')){
    Cookies.init();
    MainNav.init();
    MobileApp.init();
    Favorites.init(bootstrap);
    UserRoutes.init(bootstrap);
    document.getElementById('searchModal').addEventListener('shown.bs.modal', function () {
      document.getElementById('search-input-text').focus()
    })
  }
  
}, false);
