const InfiniteScroll = require('infinite-scroll');
import '../stylesheets/scroll_pagination.scss'

const scrollPagination = {
  init() {
    const paginatorSelector = '.scroll-pagination'
    const containerSelector = '.scroll-container'
    if (document.querySelector(paginatorSelector) && document.querySelector(containerSelector)){
      new InfiniteScroll(containerSelector, {
        path: paginatorSelector,
        append: ".scroll-item",
        status: '.scroll-status',
        hideNav: '.scroll-pagination',
        scrollThreshold: 300, // defaults to 400px
        history: false // defaults to 'append'
      })
    }
  }

}

export default scrollPagination
