import * as Clipboard from 'clipboard';
import utilities from './utilities'

const share = {
  bootstrap: '',
  sharePopover: '',
  clipboardTooltip: '',
  init(bootstrap){
    share.bootstrap = bootstrap
    document.addEventListener('share.reload', event => {
      share.addClickListener(event.detail.container);
    })
    document.dispatchEvent(new CustomEvent('share.reload', {'detail': {'container': document}}))  
  },
  addClickListener(container){
    container.querySelectorAll('.share-btn:not([data-share-disabled])').forEach((elem) => {
      if (utilities.elemIsVisible(elem)) {
        let shareEl = elem
        let altShareEl = elem.nextElementSibling
        if(navigator.canShare) {
          // altShareEl.remove()
          share.bindNavigatorShare(shareEl)
        } else {
          // if (shareEl) { shareEl.remove() }
          share.bindSharePopover(shareEl, altShareEl)
        }
      }
    })
    // disable click event for data-share-disabled links
    container.querySelectorAll('.share-btn[data-share-disabled]').forEach((elem) => {
      elem.addEventListener('click', event => {
        event.preventDefault()
      })
    })
  },
  bindNavigatorShare(link){
    link.addEventListener('click', event => {
      event.preventDefault()
      navigator.share({
        text: link.getAttribute('data-share-text'),
        title: link.getAttribute('data-share-text'), // usually ignored
        url: window.location.href
      })
      .then(_ => {})
      .catch(error => {
        console.log('navigator.share error:', error)
      });
    })
  },
  bindSharePopover(link, container){
    let popoverContent = container.innerHTML

    let shareAllowList = share.bootstrap.Tooltip.Default.allowList
    shareAllowList.button = ['data-clipboard-text', 'data-clipboard-success', 'data-clipboard-error', 'data-bs-toggle', 'data-bs-original-title', 'title']
    shareAllowList.input = ['value']

    share.sharePopover = new share.bootstrap.Popover(link, {
      html: true,
      container: 'body',
      content: popoverContent,
      placement: 'bottom',
      template: '<div class="popover share-popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-body"></div></div>',
      allowList: shareAllowList
    })

    link.addEventListener('click', event => {
      event.preventDefault()
      let link_display = window.getComputedStyle(link.parentNode.parentNode,null)
      if (link_display != 'none') {
        share.sharePopover.show()
      }
    })

    link.addEventListener('shown.bs.popover', event => {
      event.stopImmediatePropagation()
      share.bindCopyToClipboard(container)
    })
  },
  bindCopyToClipboard(container) {
    let clipboardBtn = container.querySelector('.copy-to-clipboard')

    let clipboard = new Clipboard('.copy-to-clipboard');

    // Clipboard response
    clipboard.on('success', event => {
      // show tooltip with "Copied!" text
      share.showClipboardResponse(clipboardBtn.getAttribute('data-clipboard-success'))
      event.clearSelection();
    });

    clipboard.on('error', event => {
      // show tooltip with "Press Ctrl+C to copy" text
      share.showClipboardResponse(clipboardBtn.getAttribute('data-clipboard-error'))
    });
  },
  showClipboardResponse(response){
    // let elem = container.querySelector('.clipboard-response')
    // to ensure clipboard-response on visible element:
    let elem = document.querySelector('.popover-body .clipboard-response span')
    elem.innerHTML = response
    share.sharePopover.update()
    setTimeout(function(){ elem.innerHTML = '&nbsp;' }, 3000);
  }
}

export default share
