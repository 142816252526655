import { Controller } from "@hotwired/stimulus"
import xhr_headers from '../plugins/xhr_headers.js'
import ScrollPagination from "../plugins/scroll_pagination.js"

export default class extends Controller {
  static targets = [ "resources", "filter", "form", "params" ]
  static classes = [ "active", "loading" ]
  // static values = { url: String }
  connect() {
    ScrollPagination.init();
  }
  replaceResources(html){
    this.resourcesTarget.innerHTML = html;
    ScrollPagination.init();
  }
  checkFilterStatus(event){
    if (event.target.parentElement.classList.contains('active')){
      event.preventDefault()
      event.stopPropagation()
      let url = new URL(event.target.href);
      let loadUrl = event.target.href.replace(url.search, '')
      this.loadResources(loadUrl).then((html) => {
        event.target.parentElement.classList.remove(this.activeClass)
        this.replaceResources(html)
      })
    }
  }
  filterResources(event){
    event.preventDefault();
    event.stopPropagation();
    this.filterTargets.forEach( (elem) => { elem.classList.remove(this.activeClass) } )
    const [, , xhr] = event.detail;
    this.replaceResources(xhr.responseText)
    event.target.parentElement.classList.add(this.activeClass)
  }
  searchResources(event){
    event.preventDefault();
    event.stopPropagation();
    this.resourcesTarget.classList.add(...this.loadingClasses)
    const q = this.paramsTarget.value
    const loadUrl = `${this.formTarget.action}?q=${q}`
    this.loadResources(loadUrl).then((html) => {
      this.resourcesTarget.classList.remove(...this.loadingClasses)
      this.replaceResources(html)
    })
  }
  loadResources(url){
    return fetch(url, { headers: xhr_headers })
      .then((response) => response.text())
      .then(html => {
        return html
      })
  }
}
