import UserSession from "./user_session.js";
import xhr_headers from './xhr_headers.js'

const favorites = {
  bootstrap: '',
  init(bootstrap){
    favorites.bootstrap = bootstrap
    document.addEventListener('favorites.reload', event => {
      favorites.addClickListener(event.detail.container);
    })
    let container = document.querySelector('main .fav-container')
    if (container){
      document.dispatchEvent(new CustomEvent('favorites.reload', {'detail': {'container': container}}))  
    }
  },
  addClickListener(container){
    UserSession.init(favorites.bootstrap);
    container.querySelectorAll('.user-fav').forEach((link) => {
      link.removeAttribute('data-method')
      link.addEventListener('click', event => {
        event.stopImmediatePropagation();
        event.preventDefault();
        let method = link.classList.contains('is-fav') ? 'delete' : 'post'
        fetch(link.getAttribute('href'), {
          method: method, 
          headers: xhr_headers
        }).then(response => {
          if(!response.ok) {
            // unauthorized
            response.text().then(html => {
              UserSession.showForm(html, favorites.bootstrap).then(_ => {
                favorites.toggleLink(link)
              })
            })
          } else {
            response.text().then(_ => {
              favorites.toggleLink(link)  
            })
          }
        }).catch(error => {
          console.log(`An error ocurred while ${method} user shops`)
        });
      })
    })
  },
  toggleLink(link){
    let title = link.classList.contains('is-fav') ? 'post' : 'delete'
    link.classList.toggle('is-fav')
    link.setAttribute('title', link.getAttribute(`data-title-fav-${title}`)) 
  }
}

export default favorites
