import { Controller } from "@hotwired/stimulus"
import xhr_headers from '../plugins/xhr_headers.js'

export default class extends Controller {
  static targets = [ "resources", "form", "params" ]
  static classes = [ "active", "loading" ]
  // static values = { url: String }
  connect() {

  }
  replaceResources(html){
    this.resourcesTarget.innerHTML = html;
  }
  // filterResources(event){
  //   event.preventDefault();
  //   event.stopPropagation();
  //   // this.filterTargets.forEach( (elem) => { elem.classList.remove(this.activeClass) } )
  //   const [, , xhr] = event.detail;
  //   this.replaceResources(xhr.responseText)
  //   // event.target.parentElement.classList.add(this.activeClass)
  // }
  searchResources(event){
    event.preventDefault();
    event.stopPropagation();
    
    this.resourcesTarget.classList.add(...this.loadingClasses)
    const area_id = this.paramsTarget.value
    const loadUrl = `${this.formTarget.action}?area_id=${area_id}`
    this.loadResources(loadUrl).then((html) => {
      this.resourcesTarget.classList.remove(...this.loadingClasses)
      this.replaceResources(html)
    })
  }
  loadResources(url){
    return fetch(url, { headers: xhr_headers })
      .then((response) => response.text())
      .then(html => {
        return html
      })
  }
}
