import CookiesEuBanner from 'cookies-eu-banner'

const GA_TRACKING_ID = document.getElementsByTagName("meta")['google-site-verification'].getAttribute('content');

// Global site tag (gtag.js) - Google Analytics
// it is absolutely crucial to define gtag in the global scope
window.dataLayer = window.dataLayer || [];
function gtag(){ 
  dataLayer.push(arguments);
}
gtag('js', new Date());

gtag('config', `${GA_TRACKING_ID}`); // , {'anonymize_ip': true}

let Cookies = {
  init() {
    if (document.getElementById('cookies-eu-banner')) {
      new CookiesEuBanner(() => {
        let env = document.getElementsByTagName("body")[0].getAttribute('data-env');
        if (env.match(/(.*)_cdir$/)){
          Cookies.setCookies();
        } else {
          console.log('Analytics cookie not loaded for env ' + env)
        }
      })
    }
  },
  setCookies() {
    var s = document.createElement('script');
    s.type = "text/javascript"
    s.async = "true";
    s.src = `https://www.googletagmanager.com/gtag/js?${GA_TRACKING_ID}`;
    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);

    // you can add other cookies here
  },
  // deleteCookies(cookieconsent_name) {
  //   var keep = [cookieconsent_name, "DYNSRV"];

  //   document.cookie.split(';').forEach(function(c) {
  //     c = c.split('=')[0].trim();
  //     if (!~keep.indexOf(c))
  //       document.cookie = c + '=;' + 'expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/';
  //   })
  // }
}

export default Cookies;